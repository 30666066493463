export const GET_LISTINFO_REQUEST = 'GET_LISTINFO_REQUEST';
export const GET_LISTINFO_FAILURE = 'GET_LISTINFO_FAILURE';
export const GET_LISTINFO_SUCCESS = 'GET_LISTINFO_SUCCESS'; 
export const GET_ORDERINFO_REQUEST = 'GET_ORDERINFO_REQUEST';
export const GET_ORDERINFO_FAILURE = 'GET_ORDERINFO_FAILURE';
export const GET_ORDERINFO_SUCCESS = 'GET_ORDERINFO_SUCCESS'; 


export const GET_LISTGIBRID_REQUEST = 'GET_LISTGIBRID_REQUEST';
export const GET_LISTGIBRID_FAILURE = 'GET_LISTGIBRID_FAILURE';
export const GET_LISTGIBRID_SUCCESS = 'GET_LISTGIBRID_SUCCESS'; 

export const GET_LISTMOTOR_REQUEST = 'GET_LISTMOTOR_REQUEST';
export const GET_LISTMOTOR_FAILURE = 'GET_LISTMOTOR_FAILURE';
export const GET_LISTMOTOR_SUCCESS = 'GET_LISTMOTOR_SUCCESS'; 

export const GET_LISTBETT_REQUEST = 'GET_LISTBETT_REQUEST';
export const GET_LISTBETT_FAILURE = 'GET_LISTBETT_FAILURE';
export const GET_LISTBETT_SUCCESS = 'GET_LISTBETT_SUCCESS'; 

export const GET_LISTSUN_REQUEST = 'GET_LISTSUN_REQUEST';
export const GET_LISTSUN_FAILURE = 'GET_LISTSUN_FAILURE';
export const GET_LISTSUN_SUCCESS = 'GET_LISTSUN_SUCCESS'; 

export const GET_LISTBIKE_REQUEST = 'GET_LISTBIKE_REQUEST';
export const GET_LISTBIKE_FAILURE = 'GET_LISTBIKE_FAILURE';
export const GET_LISTBIKE_SUCCESS = 'GET_LISTBIKE_SUCCESS'; 

export const GET_MOTORWHEEL_REQUEST = 'GET_MOTORWHEEL_REQUEST';
export const GET_MOTORWHEEL_FAILURE = 'GET_MOTORWHEEL_FAILURE';
export const GET_MOTORWHEEL_SUCCESS = 'GET_MOTORWHEEL_SUCCESS'; 

export const GET_BIKE_REQUEST = 'GET_BIKE_REQUEST';
export const GET_BIKE_FAILURE = 'GET_BIKE_FAILURE';
export const GET_BIKE_SUCCESS = 'GET_BIKE_SUCCESS'; 

export const GET_SCOOTER_REQUEST = 'GET_SCOOTER_REQUEST';
export const GET_SCOOTER_FAILURE = 'GET_SCOOTER_FAILURE';
export const GET_SCOOTER_SUCCESS = 'GET_SCOOTER_SUCCESS'; 

export const GET_BABY_REQUEST = 'GET_BABY_REQUEST';
export const GET_BABY_FAILURE = 'GET_BABY_FAILURE';
export const GET_BABY_SUCCESS = 'GET_BABY_SUCCESS'; 