import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom'
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_uxwnw7QAASEyGH4VyXEnY");

export default function Footer(props) {
    const handleSubmit = e => {
        e.preventDefault();
        console.log(e.target)
        if (e.target[0].value === "") {
            e.target[0].className = "inp-sent inp-date-border";
        }
        else if (e.target[1].value === "") {
            e.target[1].className = "inp-sent inp-date-border";
        }
        else {
            e.target[0].className = "inp-sent";
            e.target[1].className = "inp-sent";
            emailjs.sendForm("service_we6nzgh", "template_cegtqxu", e.target, "user_uxwnw7QAASEyGH4VyXEnY");
            alert("Сообщение отправлено");
            e.target.reset();
        }
    };
    return (
        <Fragment>
            <footer class="container bg-light footer">
                <div className="row">
                    <div className="col">
                        <section>
                            <h5>Новые разделы:</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item nav-link-footer">
                                    <NavLink exact to="/scooter/0" >Электросамокат</NavLink>
                                </li>
                                <li className="nav-item nav-link-footer">
                                    <NavLink exact to="/motorWheel/" >Мотор-колесо</NavLink>
                                </li>
                                <li className="nav-item nav-link-footer">
                                    <NavLink exact to="/babyelectromobiles/" >Самодельный Детский Электромобиль</NavLink>
                                </li>
                                <li className="nav-item nav-link-footer">
                                    <NavLink exact to="/electromobiles/elektromobili-svoimi-rukami/" >Самодельный Электромобиль</NavLink>
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div className="col">
                    <section className="footer-contacts">
                            {/* <h5>Контакты:</h5> */}
                            {/* <a className="footer-contacts-email" href="mailto:info@electro-mobiles.ru">info@electro-mobiles.ru</a> */}
                            <div className="footer-social-links">
                                <a className="footer-social-link footer-social-link-vk" href="https://vk.com/club34752196"></a>
                            </div>
                        </section>
                        <section>
                            <div className="footer-copyright">2021 © Electro-mobiles.ru — все о электромобилях. <a href="https://github.com/kiselevgleb">GK</a></div>
                            
                        </section>
                    </div>
                    <div className="col text-right">
                        <section className="footer-contacts">
                        <form className="fb-cont fb-form form-left" onSubmit={handleSubmit} >
                                    <h5 >Контакты</h5>
                                    <input className="inp-sent" type="text" placeholder="Имя" name="name"/>
                                    {/* <p className="calc-text">Email*</p> */}
                                    <input className="inp-sent" type="email" name="email" placeholder="Email"/>
                                    {/* <p className="calc-text">Сообщение</p> */}
                                    <textarea className="inp-sent" placeholder="Сообщение" name="message" ></textarea>
                                    <button type="submit" className="but-sent">Отправить сообщение</button>
                                </form>
                        </section>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
